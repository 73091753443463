<template>

  <b-modal
    :visible="isModalActive"
    :title="$t('Package Purchase')"
    ok-title="Accept"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-overlay
      :show="dataLoading"
      rounded="sm"
    >
      <b-tabs
        content-class="pt-1"
        fill
      >
        <b-tab
          v-if="dataResponse!=null"
          :title="$t('Detail')"
        >
          <detail :data-response="dataResponse" />
        </b-tab>
        <b-tab
          v-if="dataResponse!=null"
          :title="$t('Slip')"
        >
          <slip :data-response="dataResponse" />
        </b-tab>
        <b-tab
          v-if="dataResponse!=null"
          :title="$t('Time line')"
        >
          <timeline :data-response="dataResponse" />
        </b-tab>
      </b-tabs>
    </b-overlay>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Detail from './components/Detail.vue'
import Slip from './components/Slip.vue'
import Timeline from './components/Timeline.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    Detail,
    Slip,
    Timeline,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataResponse: null,
      dataLoading: false,
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id,
          } = this.data
          this.dataId = _id
          this.dataResponse = this.data
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.dataResponse = null
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
