<template>
  <div>
    <b-modal
      :visible="isModalActive"
      :title="$t('Manage Package')"
      ok-title="Accept"
      @change="(val) => $emit('update:is-modal-active', val)"
    >
      <b-row class="justify-content-center">
        <div>
          <span
            v-if="daysBetween(new Date().toISOString(), dataExpire) > 0"
            class="align-middle ml-50"
          >{{
            $t('Time left')
          }} {{ expireText(daysBetween(new Date().toISOString(), dataExpire)) }} {{
            $t('Day')
          }}</span>
          <span
            v-else
            class="align-middle ml-50"
            style="color:red;"
          >{{ $t('Out of time') }}</span>
        </div>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          v-for="(item,index) in respDataTemplate"
          :key="index"
          cols="8"
          class="mt-2 justify-content-center"
          :style="`background-color:${item.color}; border-radius: 15px;`"
        >
          <b-row
            class="p-2 justify-content-between"
            @click="selectedPackage(item)"
          >
            <div>
              <b><span>{{ item.name }}</span></b>
              <b v-if="currentPackage == item._id"><span>({{ $t('ใช้งานอยู่') }})</span></b>
            </div>
            <feather-icon
              v-if="servicePackageTemplateId != item._id"
              class="ml-1 cursor-pointer"
              icon="CircleIcon"
              size="16"
            />
            <feather-icon
              v-if="servicePackageTemplateId == item._id"
              class="ml-1 cursor-pointer"
              icon="CheckCircleIcon"
              size="16"
            />
          </b-row>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-2">
        <span>{{ $t("Day") }}:</span>
        <b-col cols="3">
          <b-form-input
            id="register-detail"
            v-model="day"
            :disabled="true"
            name="register-detail"
            :placeholder="$t('Day')"
          />

        </b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal.modal-center
          :disabled="!servicePackageTemplateId"
          variant="success"
          size="sm"
          class="mr-2"
        >
          {{ $t('Buy Package') }}
        </b-button>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <p class="float-left mb-0" />
          <div
            class="float-right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class=""
              @click="$emit('update:is-modal-active', false)"
            >
              {{ $t('Close') }}
            </b-button>
          </div>

        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-center"
      centered
      :title="$t('Upload Slip')"
      hide-footer
    >
      <b-overlay
        :show="dataLoading"
        rounded="sm"
      />
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <span style="fontSize:16px;">{{ $t('Price') }} : {{ price }}</span>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('Slip')"
            label-for="register-image"
          >
            <!-- media -->
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img
                    v-if="image"
                    ref="previewEl"
                    rounded
                    :src="image"
                    height="350"
                  />
                  <b-img
                    v-else
                    ref="previewEl"
                    :src="require('@/assets/images/blank/no_image.png')"
                    rounded
                    height="250"
                  />
                </b-link>
                <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="$refs.refInputEl.$el.click()"
                >
                  {{ $t("Upload") }}
                </b-button>
                <b-form-file
                  ref="refInputEl"
                  accept=".webp, .jpeg, .jpg, .png, .gif"
                  :hidden="true"
                  plain
                  @change="inputImageRenderer"
                />
                <!--/ upload button -->

                <!-- reset -->
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="resetImage"
                >
                  {{ $t("Reset") }}
                </b-button>
                <!--/ reset -->
              </b-media-body>
            </b-media>
            <!--/ media -->
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            v-if="dataLoading == false"
            :disabled="image == '' || image == null"
            class="mt-3"
            variant="success"
            @click="payPackage()"
          >
            {{ $t('Buy Package') }}
          </b-button>
        </b-col>

      </b-row>
    </b-modal>
  </div>
</template>

<script>

// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  // BTabs,
  // BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  // BForm,
  BFormInput,
  // BFormGroup,
  BOverlay,
  BFormGroup,
  BMedia,
  BMediaAside,
  BLink,
  BImg,
  BMediaBody,
  BFormFile,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
// import { ThumbsUpIcon } from 'vue-feather-icons'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // BTabs,
    // BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BOverlay,
    // ValidationProvider,
    // ValidationObserver,
    // BForm,
    BFormInput,
    BFormGroup,
    BMedia,
    BMediaAside,
    BLink,
    BImg,
    BMediaBody,
    BFormFile,
    // BFormGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataResponse: null,
      dataLoading: false,
      servicePackageTemplateId: null,
      price: 0,
      currentPackage: null,
      dataExpire: '',
      day: 0,
      image: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    respDataTemplate() {
      return store.state[this.storeModuleName].respDataTemplate != null ? store.state[this.storeModuleName].respDataTemplate.data : []
    },

  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id,
            servicePackageId,
            expirePackage,
          } = this.data
          this.dataId = _id
          if (servicePackageId != null) {
            this.currentPackage = servicePackageId.servicePackageTemplateId
          }
          this.dataExpire = expirePackage
          this.servicePackageTemplateId = null
          this.day = 0
          this.getTemplatePackage()
        }
      }
    },
  },
  methods: {
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      console.log(bvModalEvent)
    },
    payPackage() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to Pay Package')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.dataLoading = true
            const obj = {
              servicePackageTemplateId: this.servicePackageTemplateId,
              profilePartnerId: this.dataId,
              day: this.day,
              img: this.image,
            }
            store
              .dispatch(`${this.storeModuleName}/payPackage`, obj)
            // eslint-disable-next-line no-unused-vars
              .then(result => {
                if (result.data.status) {
                  this.dataLoading = false
                  this.$emit('update:is-modal-active', false)
                  this.$bvModal.hide('modal-center')
                  this.$emit('update:show', false)
                }
              }).catch(error => {
                this.show = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // console.log('fetchUsers Error : ', error)
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              })
          }
        })
    },
    selectedPackage(value) {
      this.price = value.storeSetting.amount
      this.servicePackageTemplateId = value._id
      this.day = value.limitedTime
    },
    expireText(value) {
      if (value < 1) {
        return this.$t('less than 1')
      }
      return Math.ceil(value)
    },
    treatAsUTC(date) {
      const result = new Date(date)
      result.setMinutes(result.getMinutes() - result.getTimezoneOffset())
      return result
    },
    daysBetween(startDate, endDate) {
      const millisecondsPerDay = 24 * 60 * 60 * 1000
      return (this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerDay
    },
    initValues() {
      this.dataId = null
      this.dataResponse = null
    },
    getTemplatePackage() {
      this.dataLoading = true
      store
        .dispatch(`${this.storeModuleName}/getTemplatePackage`)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.dataLoading = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    viewData() {
      this.dataLoading = true
      store
        .dispatch(`${this.storeModuleName}/view`, { id: this.dataId })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.dataResponse = result.data.data
          this.dataLoading = false

        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.dataLoading = false

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    verifyData() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to verify')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        // eslint-disable-next-line no-unused-vars
        .then(value => {
          if (value) {
            this.dataLoading = true
            store
              .dispatch(`${this.storeModuleName}/verify`, { id: this.dataId })
            // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.dataResponse = result.data.data
                this.dataLoading = false
              }).catch(error => {
                this.dataLoading = false

                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // console.log('fetchUsers Error : ', error)
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              })
          }

        //  else {
        //   this.showToast(
        //     'warning',
        //     'top-right',
        //     `${this.$t('DELETE_CANCEL')}!`,
        //     'AlertCircleIcon',
        //     this.$t('DELETE_CANCEL'),
        //   )
        // }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
