<template>
  <div v-if="dataResponse!=null">
    <b-list-group class="mb-2">
      <b-list-group-item>
        {{ this.$t('Amount') }} : {{ dataResponse.amount }}
      </b-list-group-item>
      <b-list-group-item>
        {{ this.$t('Name') }} : {{ dataResponse.servicePackageId.profilePartnerId.firstName }} {{ dataResponse.servicePackageId.profilePartnerId.lastName }}
      </b-list-group-item>
      <b-list-group-item>
        {{ this.$t('Package Service') }} : {{ dataResponse.servicePackageId.name }} : {{ dataResponse.servicePackageId.detail }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BImg,
  BMedia,
  BTable,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BTable,
    BListGroup,
    BListGroupItem,

  },
  directives: {
    Ripple,
  },
  props: {
    dataResponse: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tableColumns() {
      return [
        { key: '_id', label: '#', sortable: false },
        { key: 'product', label: this.$t('Product name'), sortable: false },
        { key: 'amount', label: this.$t('Amount'), sortable: false },
      ]
    },
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>
