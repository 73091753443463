// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataCurrentPackage: null,
    respDataTemplate: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_CURRENT_PACKAGE(state, payload) {
      state.respDataCurrentPackage = payload
    },
    GET_TEMPLATE(state, payload) {
      state.respDataTemplate = payload
    },
    UPDATE_DATA_PAYPACKAGE(state, payload) {
      state.respData.data.unshift(payload.data)
    },

  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/partner/hero/store-queue/packagePurchase/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}&profilePartnerId=${item.profilePartnerId}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCurrentPackage({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/partner/hero/store-queue/packagePurchase/getCurrentPackage')
          .then(response => {
            commit('GET_CURRENT_PACKAGE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTemplatePackage({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/partner/hero/store-queue/packagePurchase/getTemplatePackage')
          .then(response => {
            commit('GET_TEMPLATE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    payPackage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/partner/hero/store-queue/packagePurchase/payPackage', {
          servicePackageTemplateId: item.servicePackageTemplateId, profilePartnerId: item.profilePartnerId, day: item.day, slip: item.img,
        })
          .then(response => {
            commit('UPDATE_DATA_PAYPACKAGE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
